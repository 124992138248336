import React from "react";

import { HelpIcon } from "@packages/ds";
import { useIntl } from "react-intl";

import { useClickOutside } from "Hooks/useClickOutside";

import * as S from "./styles";

type Props = {
  layoutRef: any;
  setIsOpen: (value: boolean | ((arg: boolean) => boolean)) => void;
  isOpen: boolean;
};

const HelpButton = ({ layoutRef, setIsOpen }: Props) => {
  const intl = useIntl();

  useClickOutside(layoutRef, () => setIsOpen(false));

  return (
    <S.Container id="help-button" onClick={() => setIsOpen(value => !value)}>
      <S.IconContainer>
        <HelpIcon />
      </S.IconContainer>
      <S.HelpText>{intl.formatMessage({ id: "help_text" })}</S.HelpText>{" "}
    </S.Container>
  );
};

export default HelpButton;
